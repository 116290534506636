* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  background-color: #fff;
}
body.modal-open {
  overflow-y: hidden;
}

body {
  background-color: #fff;
}
header {
  position: relative;
  z-index: 99;
}

header a {
  text-decoration: none;
  color: #fff;
  font-family: "Inter";
  margin-right: 25px;
  position: relative;
}

header a::before {
  content: "";
  position: absolute;
  bottom: -5px;
  right: 0;
  width: 0;
  height: 2px;
  border-radius: 10px;
  background-color: #fff;
  transition: 0.2s all ease-in-out;
}

header a[aria-current="page"],
header a.focus_link {
  text-decoration: none;
  color: #fff;
}

header a[aria-current="page"]::before,
header a.focus_link::before,
header a:hover::before {
  width: 50%;
  z-index: 5;
}

.primary-navigation {
  width: 290px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.151);
  clip-path: inset(0px -15px 0px 0px);
  background-color: #fff;
}

main {
  width: calc(100% - 290px);
  min-height: calc(100vh - 120.5px);
}

input:focus-visible,
input:focus,
input:focus-within {
  outline: none;
}

@media (max-width: 900px) {
  .primary-navigation {
    width: 290px;
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-290px);
    z-index: 999;
    height: 100vh;
    transition: 0.45s all ease-in-out;
    overflow-y: auto;
  }

  .primary-navigation.open,
  .overlay_ue0.open {
    transform: translateX(0);
    opacity: 1;
  }

  .overlay_ue0 {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(-1000px);
    background-color: rgba(0, 0, 0, 0.534);
    z-index: 99;
    transition: 0.4s all ease-in-out;
    opacity: 0.2;
  }

  main {
    width: 100%;
  }

  header a {
    color: #000;
    margin-right: 0;
    display: block;
    width: 100%;
    padding: 15px 10px;
  }

  header a::before {
    background-color: #000;
    bottom: 5px;
  }

  header a[aria-current="page"] {
    color: #000;
  }

  header a[aria-current="page"]::before,
  header a:hover::before {
    width: 100%;
  }
}

.sign_in_page {
  background-image: url("./assets/images/log-bg.png");
}

.sign_up_page {
  background-image: url("./assets/images/Rectangle-162.png");
}

.sign_in_page,
.sign_up_page,
.sign_in_admin {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.simple_link {
  text-decoration: none !important;
  color: #333 !important;
  cursor: pointer;
  font-weight: 600;
}

.MuiTabs-indicator {
  display: none !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #40b92d !important;
  border-bottom: 1px solid #40b92d !important;
}
.pagi_active {
  background-color: #fff;
  color: #000;
  border-radius: 4px;
}
.cancel-btn {
  cursor: "pointer";
  color: "red";
  border: "1px solid red";
  border-radius: "50%";
  background-color: "white";
  font-size: medium;
}

input[type="number"]::-webkit-outer-spin-button.hide_arrows,
input[type="number"]::-webkit-inner-spin-button.hide_arrows {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"].hide_arrows {
  -moz-appearance: textfield;
}

.cube {
  background-color: #000;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cube img{
  width: 16px;
}

.cube.sm{
  width: 22px;
  height: 22px;
}
.cube img{
  width: 12px;
}

.mycustom_tabs {
  overflow-x: auto;
}

.mycustom_tabs .Mui-selected {
  color: #40b92d;
  background-color: rgba(0, 0, 0, 0.02) !important;
  position: relative;
}
.mycustom_tabs .Mui-selected::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  background-color: #40b92d;
}
.MuiList-root.MuiMenu-list > li{
  min-height: auto !important;
}